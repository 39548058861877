import React, { useContext } from 'react';
import * as S from './PlusMinusButtons.styles';
import FunnelContext from '../../context/FunnelContext';

const PlusMinusButtons = ({ tubs }) => {
  const {
    currentTubIndex = 0,
    setCurrentTubIndex,
    setTubIndex,
    setCurrentProduct,
    currentCategory = 'subscriptions'
  } = useContext(FunnelContext);

  const productPrefix =
    currentCategory === `onetime` ? `onetime` : `subscription`;

  const handleChangePlus = () => {
    if (tubs.length - 1 > currentTubIndex) {
      setCurrentTubIndex(currentTubIndex + 1);
      setTubIndex(currentTubIndex + 1);
      setCurrentProduct(`${productPrefix}_${currentTubIndex + 1}`);
    } else {
      return currentTubIndex;
    }
  };

  const handleChangeMinus = () => {
    if (currentTubIndex > 0) {
      setCurrentTubIndex(currentTubIndex - 1);
      setTubIndex(currentTubIndex - 1);
      setCurrentProduct(`${productPrefix}_${currentTubIndex - 1}`);
    } else {
      return currentTubIndex;
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <S.Label>Quantity:</S.Label>
      <S.Button
        onClick={handleChangeMinus}
        className={`${currentTubIndex > 0 ? `` : `not-active`}`}
      >
        <S.Minus></S.Minus>
      </S.Button>
      <S.TubCount>{currentTubIndex + 1}</S.TubCount>
      <S.Button
        onClick={handleChangePlus}
        className={`${tubs.length - 1 > currentTubIndex ? `` : `not-active`}`}
      >
        <S.Plus></S.Plus>
      </S.Button>
    </div>
  );
};

export default PlusMinusButtons;
