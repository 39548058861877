import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  font-family: 'Neuzeit Grotesk', sans-serif;
`;

export const Button = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #001c72;
  border-radius: 4px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  cursor: pointer;
  border: none;
  font-size: 26px;
  padding: 0;
  margin: 0;
  &.not-active {
    background: #e9ebed;
  }
`;

export const TubCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 40px;
  height: 40px;
`;

export const Label = styled.div`
  display: flex;
  margin-right: 8px;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #33498e;
`;

export const Minus = styled.div`
  width: 15px;
  height: 15px;
  position: relative;

  &:before {
    width: 15px;
    height: 3px;
    background-color: #fff;
    content: '';
    display: block;
    position: absolute;
    top: 6px;
  }
`;
export const Plus = styled.div`
  width: 15px;
  height: 15px;
  position: relative;

  &:before {
    width: 15px;
    height: 3px;
    background-color: #fff;
    content: '';
    display: block;
    position: absolute;
    top: 6px;
  }
  &:after {
    width: 3px;
    height: 15px;
    background-color: #fff;
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 6px;
  }
`;
